import { Component, OnInit } from '@angular/core';
import { DarkModeService } from 'src/app/dark-mode/dark-mode.service';
import { skillset, Stack } from 'src/app/models/stack.interface';

@Component({
  selector: 'app-my-skills',
  templateUrl: './my-skills.component.html',
  styles:[`
    .animate-gradient {
  background-size: 300%;
  -webkit-animation: animatedgradient 4s ease infinite alternate;
  -moz-animation: animatedgradient 4s ease infinite alternate;
  animation: animatedgradient 4s ease infinite alternate;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
    `]
})
export class MySkillsComponent implements OnInit {
  skillset:Stack[] = skillset; 
  constructor(public darkMode:DarkModeService) { }

  ngOnInit(): void {
  }
}
