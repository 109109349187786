<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 780 1200" style="enable-background:new 0 0 951.1 1406.5;" xml:space="preserve"
	 >

<g transform="scale(.8)" data-aos="animation-scale-y" data-aos-delay="200" >
	<g id="base" >
		
				<polygon class="fill-current dark:text-gray-900" points="686.8,759.3 685.5,1206.3 510,1105 511.3,657.9 				"/>
		<polygon class=" fill-current dark:text-indigo-700" points="804.7,844 673.3,920.3 672.1,1367.3 803.5,1291 949.2,1206.3 950.5,759.3 		"/>

		<polygon class="fill-current dark:text-indigo-600" points="291.6,958.5 290.3,1405.5 567.5,1244.5 568.7,797.4 		"/>
		<g>
			<polygon class="fill-current text-indigo-600 dark:text-indigo-300" points="950.5,759.3 804.7,844 673.3,920.3 541.1,844 686.8,759.3 511.3,657.9 365.6,742.6 233.3,666.3 
				364.7,589.9 510.4,505.2 657.1,589.9 803.8,674.6 			"/>
		</g>
		<g>
			<g>
				<polygon class="fill-current text-gray-100 dark:text-indigo-500" points="673.3,920.3 672.1,1367.3 539.8,1291 541.1,844 				"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="fill-current text-indigo-600 dark:text-indigo-300" points="568.7,797.4 291.6,958.5 159.3,882.1 12.6,797.5 144.1,721.1 305.1,814.1 450.9,729.4 				"/>
			</g>
		</g>
		<polygon class="fill-current text-gray-100 dark:text-indigo-400" points="159.3,882.1 12.6,797.5 11.4,1244.5 158.1,1329.1 290.3,1405.5 291.6,958.5 		"/>
	</g>

	<g id="superior">
		<g>
			
				<polygon class="fill-current text-gray-100 dark:text-indigo-300" points="365.6,243.8 364.3,690.9 232,614.5 233.3,167.5 				"/>
		
		</g>
		<g>
			<polygon class="fill-current text-gray-900 dark:text-indigo-500" points="511.3,159.2 510,606.2 364.3,690.9 365.6,243.8 			"/>
		</g>
		<g>
			<g>
				<polygon class="fill-current text-gray-200 dark:text-indigo-600" points="686.8,260.5 685.5,707.5 510,606.2 511.3,159.2 				"/>
			</g>
		</g>
		
		<polygon class="fill-current text-gray-900 dark:text-indigo-600" points="804.7,345.2 673.3,421.5 672.1,868.5 803.5,792.2 949.2,707.5 950.5,260.5 		"/>
		<g >
			<polygon class="fill-current text-indigo-500 dark:text-indigo-100" points="568.7,298.7 291.6,459.7 159.3,383.4 12.6,298.7 144.1,222.3 305.1,315.3 450.9,230.6 				"/>
			<polygon class="fill-current text-gray-900 dark:text-indigo-500" points="291.6,459.7 290.3,906.7 567.5,745.7 568.7,298.7 		"/>
			<polygon class="fill-current text-gray-100 dark:text-indigo-300" points="159.3,383.4 12.6,298.7 11.4,745.7 158.1,830.4 290.3,906.7 291.6,459.7 		"/>
		
		</g>
		
		<g>
			<polygon class="fill-current text-indigo-500 dark:text-indigo-100" points="950.5,260.5 804.7,345.2 673.3,421.5 541.1,345.2 686.8,260.5 511.3,159.2 365.6,243.8 233.3,167.5 
				364.7,91.1 510.4,6.4 657.1,91.1 803.8,175.8 			"/>
		</g>
		<g>
			<g>
				<polygon class="fill-current text-gray-100 dark:text-indigo-300" points="673.3,421.5 672.1,868.5 539.8,792.2 541.1,345.2 				"/>
			</g>
		</g>
		<g>
			
		</g>
	</g>
</g>
</svg>
