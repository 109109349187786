<div [@animatedEntrance] *ngIf="isVisible" class="fixed z-50 w-screen flex flex-col h-screen transition-all text-gray-900 bg-gray-50 dark:bg-indigo-600 dark:text-gray-50" [style]="theme">
    <div [class]="'w-screen z-50  right-0  flex justify-between py-4 px-5 md:px-16 h-24 top-0 absolute md:relative'">
        <div (click)="close()" [class]="'cursor-pointer mt-5 w-full h-[min-content] flex justify-end  hover:text-indigo-400'"><em [class]="'transition-all text-xl xl:text-5xl pi pi-times active:scale-50 p-5 '"></em></div>
        </div>

    <ng-container *ngTemplateOutlet="template?template:default"></ng-container>
</div>

<ng-template #default>

    <div class="flex divide-y-4 lg:divide-x-4 lg:divide-y-0 transition-all divide-indigo-500 dark:divide-gray-50  mb-24 gap-5 w-screen flex-col lg:flex-row h-full">
        <div class="flex-1">
            <div class="w-full flex flex-col items-center  justify-center lg:items-end h-full ">
                <div class="flex flex-col mt-0 md:mt-0 md:flex-row items-center md:justify-end gap-3">
                    <svg width="150" height="150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 825.63 825.63" >
                        <g>
                          <path class="transition-all fill-current text-gray-900 dark:text-gray-50" d="M684.12,229.18V496.83A135.24,135.24,0,0,1,548.88,632.06a137.07,137.07,0,0,1-15.38-.86A135.29,135.29,0,0,0,413.65,465.56V229.18a135.24,135.24,0,1,1,270.47,0Z" transform="translate(-1.5 -1.91)" />
                          <path class="transition-all fill-current text-gray-900 dark:text-gray-50" d="M306.2,504.66A135.49,135.49,0,0,0,270.28,631.8c-2.82.17-5.68.26-8.55.26A135.2,135.2,0,1,1,393.27,465.31,134.8,134.8,0,0,0,306.2,504.66Z" transform="translate(-1.5 -1.91)" />
                          <path class="transition-all fill-current text-indigo-500 dark:text-bluegray-900" d="M533.5,631.2a135.27,135.27,0,0,1-263.22.6,135.38,135.38,0,0,0,123-166.49q4.25-.26,8.55-.26,6,0,11.83.51v31.27A135.28,135.28,0,0,0,533.5,631.2Z" transform="translate(-1.5 -1.91)" />
                          <path class="transition-all fill-current text-indigo-300 dark:text-indigo-400" d="M397,496.83a135.26,135.26,0,0,1-126.68,135,135.29,135.29,0,0,1,123-166.49A135.83,135.83,0,0,1,397,496.83Z" transform="translate(-1.5 -1.91)" />
                          <path class="transition-all fill-current text-indigo-300 dark:text-indigo-400" d="M537.05,600.28a135.77,135.77,0,0,1-3.55,30.92A135.28,135.28,0,0,1,413.65,496.83V465.56a135.25,135.25,0,0,1,123.4,134.72Z" transform="translate(-1.5 -1.91)" />
                        </g>
                      </svg>
                      <div class="flex-1 flex h-auto">
                        <h6 class="text-3xl md:text-7xl font-bold">jorgepaz.cl</h6>
                    </div>
                </div>
                <div class="py-5 flex flex-col lg:flex-row gap-4  ">
                    <div (click)="scroll('contact')" class="button button-sm"><em class="pi pi-envelope"></em> {{"WRITE_ME"|translate}}</div>
                    <a href="https://github.com/jorgepazp" target="_blank" rel="noopener noreferrer">
                        <div class="button button-sm"><em class="pi pi-github"></em> {{"CONTRIBUTIONS"|translate}}</div>
                    </a>
                    <a href="https://www.linkedin.com/in/jorge-paz-pavez-34878517a/" target="_blank" rel="noopener noreferrer">
                        <div class="button button-sm"><img alt="linkedin" class="w-4" src="assets/images/linkedin.svg">  Linkedin</div>
                    </a>
                   
                </div>
                <div class="flex flex-col-reverse justify-center items-center md:flex-row md:justify-between gap-4">
                    <theme-switch></theme-switch>
                    <app-language></app-language>
                </div>

            </div>
        </div>
        <div class="hidden flex-1 md:flex flex-col justify-center lg:gap-8 px-8">
            <a href="http://blog.jorgepaz.cl"   target="_self">
                <h1 [class]="'transition-all text-3xl sm:text-5xl md:text-6xl font-bold stroke-current text-gray-50 dark:text-indigo-600 hover:text-gray-900 dark:hover:text-gray-50 cursor-pointer '+(darkmode?'outline':'outline-d')">Blog</h1>
            </a>          
            <h1 (click)="scroll('hero-section')" [class]="'transition-all text-3xl sm:text-5xl md:text-6xl font-bold stroke-current text-gray-50 dark:text-indigo-600 hover:text-gray-900 dark:hover:text-gray-50 cursor-pointer '+(darkmode?'outline':'outline-d')">{{"HOME"|translate}}</h1>
            <h1 (click)="scroll('project')" [class]="'transition-all text-3xl sm:text-5xl md:text-6xl font-bold stroke-current text-gray-50 dark:text-indigo-600 hover:text-gray-900 dark:hover:text-gray-50 cursor-pointer '+(darkmode?'outline':'outline-d')">{{"SKILLS.TITLE"|translate}}</h1>
            <h1 (click)="scroll('career')" [class]="'transition-all text-3xl sm:text-5xl md:text-6xl font-bold stroke-current text-gray-50 dark:text-indigo-600 hover:text-gray-900 dark:hover:text-gray-50 cursor-pointer '+(darkmode?'outline':'outline-d')">{{"CAREER.TITLE"|translate}}</h1>
            <h1 (click)="scroll('contact')" [class]="'transition-all text-3xl sm:text-5xl md:text-6xl font-bold stroke-current text-gray-50 dark:text-indigo-600 hover:text-gray-900 dark:hover:text-gray-50 cursor-pointer '+(darkmode?'outline':'outline-d')">{{"CONTACT"|translate}}</h1>
        
        </div>
    </div>
    
</ng-template>
