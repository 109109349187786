import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-keep-reading',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div (click)="re()" class=" z-30 w-full flex relative overflow-hidden" [ngClass]="{'h-auto':isActive,'h-[75px]':!isActive}" >
      <ng-content></ng-content>
      <div class="cursor-pointer absolute bottom-0 w-full font-bold font-sans text-center">
        <div class="z-0 absolute w-full bg-gradient-to-t from-indigo-100 dark:from-bluegray-900 h-6 bottom-0"></div>
      <span class="z-10 relative" >
        KEEP READING
      </span>  
      </div>
    </div>
  `,
  styleUrl: './keep-reading.component.scss'
})
export class KeepReadingComponent {
  @Input() isActive:boolean = false;

  re(){
    this.isActive = !this.isActive;
    console.log("hola")
  }
}
