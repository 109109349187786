<app-overlay-layer></app-overlay-layer>
<toast></toast>
<div [class]="'overflow-x-hidden z-0 w-screen bg-indigo-100 dark:bg-bluegray-900 text-bluegray-800 font-sans dark:text-bluegray-50 '+(lockScroll?'max-h-screen overflow-y-hidden ':'')">
        <app-navbar [direction]="direction" [sticky]="sticky"></app-navbar>
        
        <app-profile id="profile" ></app-profile>


        <app-my-skills id="project"></app-my-skills>


        <app-my-history id="career"></app-my-history>
        
     

        <app-contact id="contact"></app-contact>
</div>
