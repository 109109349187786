<div class="relative  w-full flex flex-col items-center justify-center min-h-screen h-auto ">
    <div class="absolute flex flex-col h-full w-full">
        <div class="w-full -mb-1">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 1440 320">
                <path class="fill-current text-indigo-500 w-full" fill-opacity="1" d="M0,96L48,128C96,160,192,224,288,224C384,224,480,160,576,138.7C672,117,768,139,864,133.3C960,128,1056,96,1152,80C1248,64,1344,64,1392,64L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
              </svg>
            
        </div>
        
          <div class="bg-indigo-500 h-full w-screen ">
          
          </div>
    </div>

    <div id="custom-wrapper" class="xl:divide-x divide-indigo-500 z-20 flex flex-col xl:flex-row  bg-gray-50 rounded-3xl rounded-b-3xl xl:rounded-b-3xl  w-11/12 md:w-4/6 p-2 md:py-24 md:px-8 lg:p-24 drop-shadow-2xl text-bluegray-900">
        <div class="h-96 lg:h-[500px] w-screen sm xl:flex-[2]" >
            <div class="flex absolute"  data-aos="fade-up" data-aos-delay="0">
    
                <app-scene class=" w-full "></app-scene>
            </div>
            <div class="flex h-full items-end ">
                <h1  data-aos="fade-down-right" data-aos-delay="300" class="min-h-min px-8 top-72 md:w-[30%] xl:w-[100%] text-5xl sm:text-6xl sm:top-80 lg:top-96 xl:top-80 w-11/12 2xl:w-3/4  md:text-5xl   lg:text-6xl xl:text-7xl 2xl:text-8xl font-bold mix-blend-exclusion text-indigo-50 ">{{"MOTTO"|translate}}</h1>
            </div>
            
            
        </div>
        <div [formGroup]="form" class="flex-1 px-8 flex flex-col justify-end">

                <div class="flex flex-col text-3xl font-bold items-start  mt-4 md:mt-0">
                    <h1>{{"CONTACT"|translate}} </h1>
                <!-- <span class="mb-2 ml-2 text-sm font-normal"><span class="font-mono text-lg">*</span> {{"MAIL.TOOLTIPS.REQUIRED"|translate}}</span> -->

                </div>
                <!-- <div class="transition-all focus-within:text-indigo-500 mb-4">
                    <label for="email" [class]="'text-lg font-mono '+(form.controls.mail.invalid && form.controls.mail.touched ? 'text-red-600':'') ">{{"EMAIL"|translate}}*</label>
                    <input formControlName="mail" id="email"  email type="email" class=" ml-2 transition-all w-full rounded-lg bg-indigo-100 border-0 outline-none focus:text-indigo-600 focus:border-[0.5px] focus:border-indigo-500">
                    <div class="h-2">
                        <span class="pl-2 text-red-600 text-xs" [hidden]="form.controls.mail.valid || form.controls.mail.untouched">{{"MAIL.TOOLTIPS.EMAIL"|translate}}</span>
                    </div>
                </div>
                <div class="transition-all focus-within:text-indigo-500 mb-3">
                    <label for="name" class="text-lg font-mono ">{{"NAME"|translate}}*</label>
                    <input formControlName="name" id="name" type="text" class="ml-2 transition-all w-full rounded-lg bg-indigo-100 border-0 outline-none focus:text-indigo-600 focus:border-[0.5px] focus:border-indigo-500">
                    <div class="h-2">
                        <span class="pl-2 text-red-600 text-xs" [hidden]="form.controls.name.valid || form.controls.name.untouched">{{"MAIL.TOOLTIPS.NAME"|translate}}</span>
                    </div>
                </div>

                <div class="transition-all focus-within:text-indigo-500 mb-4">
                    <label for="message" class="text-lg font-mono ">{{"SUBJECT"|translate}}</label>
                    <textarea formControlName="message" id="message"type="text" class="ml-2 transition-all h-24 min-h-[2rem] max-h-52 w-full rounded-lg bg-indigo-100 border-0 outline-none focus:text-indigo-600 focus:border-[0.5px] focus:border-indigo-500"></textarea>
                </div> -->
                <div class="flex flex-col  divide-y">

                    <!-- <div class="w-full flex justify-end">
                        <button *ngIf="!didContact" (click)="sendEmail()" [disabled]="isSaving || form.invalid "  class="p-4 disabled:opacity-60 disabled:cursor-not-allowed disabled:active:translate-y-0 transition-all font-mono bg-indigo-600 focus:ring-8 focus:ring-indigo-300 active:translate-y-2 rounded-2xl text-gray-50 text-lg">
                            <div class="flex justify-between items-center">
                                <ng-container *ngIf="isSaving">
                                    <span class="mr-5">{{"SENDING"|translate}}</span>
                                <em class="pi pi-spinner pi-spin"></em>
                                </ng-container>
                                <ng-container *ngIf="!isSaving">
                                    <span class="mr-5">{{"SEND"|translate}}</span>
                                    <em class="pi pi-arrow-right"></em>
                                </ng-container>
                                
            
                            </div>
                        </button>
                        <div *ngIf="didContact"  class="p-4 select-none bg-indigo-100 text-indigo-700 flex justify-between items-center rounded-2xl text-lg">
                            <span class="mr-5 font-mono">{{"SENT"|translate}}</span>
                            <em class="pi pi-check"></em>
                        </div>
                    </div>
                     -->
                    
                     📩 paz.jorge1&commat;gmail.com
                     <br>
                     📍 Concepción, Chile
                     <br>
                     💻 Software Engineer
                   <div class="flex items-stretch  justify-start gap-5 pt-4 mt-4 pb-4 lg:pb-0">
                    
                    <a href="mailto:paz.jorge1@gmail.com">
                        <button class="transition-all hover:scale-105 active:translate-y-1  bg-indigo-200 text-2xl p-4 rounded-lg"><em class="text-indigo-700 pi pi-envelope "></em></button>

                    </a>
                    <a href="https://www.linkedin.com/in/jorge-paz-pavez-34878517a/" target="_blank" rel="noopener noreferrer">
                        <button  class="h-full transition-all hover:scale-105 active:translate-y-1  bg-indigo-200 text-xl p-4 rounded-lg">                    
                        
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 308.5 313.5" class="w-6" xml:space="preserve">

<g>
	<path style="fill: #4338ca;" d="M309.3,27.8c0,84,0,167.9,0,251.9c-0.3,0.7-0.7,1.4-0.7,2.2c-1,9.3-5.8,16.1-13.7,20.8
		c-4.9,2.9-10.3,3.6-15.8,3.6c-81.7,0-163.3,0-245,0c-6.9,0-13.4-1.3-19-5.6c-8.1-6.2-11.1-14.7-11.1-24.5c0-79.8,0-159.6,0-239.3
		c0-3.7,0.2-7.3,0.5-11c0.8-11.9,9.9-21.4,21.5-24c1.5-0.3,3-0.6,4.6-0.9c84,0,167.9,0,251.9,0c0.8,0.3,1.6,0.7,2.5,0.8
		c11.3,1,21.9,10.8,23.5,21.8C308.6,24.9,309,26.3,309.3,27.8z M171.6,131.2c0-3.1,0-6.2,0-9.2c0-5.9-3.1-9-8.9-9
		c-10.9,0-21.9,0-32.8,0c-6.3,0-9.4,3.1-9.4,9.3c0,47.2,0,94.5,0,141.7c0,6.1,3.3,9.4,9.4,9.4c10.7,0,21.5,0,32.2,0
		c6.4,0,9.5-3.1,9.5-9.5c0-24.1,0-48.1,0-72.2c0-8,1.7-15.5,5.1-22.7c4.9-10.1,14.2-15.4,25.5-14.3c10.8,1,18.1,7.2,20.8,18.3
		c1.4,5.5,2.2,11.3,2.3,17c0.2,24.5,0.1,48.9,0.1,73.4c0,7.3,2.8,10,10.1,10c10.8,0,21.7,0,32.5,0c6.3,0,9.6-3.2,9.6-9.5
		c-0.1-28.9-0.1-57.9-0.6-86.8c-0.2-11.6-2-23.1-6.4-34c-5.4-13.6-14.4-23.7-28.4-28.7c-11.9-4.2-24.1-4.5-36.5-2.8
		c-13.8,1.9-25.2,8-33.5,19.5C172.2,131,172,131,171.6,131.2z M47.6,193.1c0,23.3,0,46.5,0,69.8c0,7.4,3.1,10.4,10.5,10.4
		c9.8,0,19.7,0,29.5,0c7.9,0,11-3.1,11-11c0-46.1,0-92.3,0-138.4c0-0.7,0-1.4,0-2.1c-0.3-5.6-3.7-8.9-9.4-8.9c-10.6,0-21.3,0-31.9,0
		c-6.7,0-9.8,3.1-9.8,9.8C47.6,146.1,47.6,169.6,47.6,193.1z M105.9,67.2c0-18.2-14.8-33.1-32.9-33.1C55,34.1,40.1,48.9,40.1,67
		c0.1,18.9,14.8,32.9,33,33C91.1,100.2,105.9,85.2,105.9,67.2z"/>
</g>
</svg>
                        </button>
                    </a>    
                    <a href="https://github.com/jorgepazp" target="_blank" rel="noopener noreferrer">
                        <button class="transition-all hover:scale-105 active:translate-y-1  bg-indigo-200 text-2xl p-4 rounded-lg"><em class="text-indigo-700 pi pi-github "></em></button>

                    </a>
                 
                    </div>
                </div>
                
        </div>

        
    </div>
</div>
<div class="bg-indigo-700 text-indigo-100 h-full w-screen p-2 text-center">
      {{"MADE_BY_SELF"|translate}} - 2024
</div>