<section class="h-auto mt-24  mb-24">
    <div class="relative flex h-auto w-screen  lg:flex-row flex-col ">
        <div class=" flex flex-1 px-7 md:px-14 lg:px-36  flex-col justify-center relative  max-w-[100vw] ">
                <div class="flex items-center mb-5 ">
                    <div class="h-0.5 w-10 bg-indigo-600 dark:bg-indigo-300 mr-5 ">
                    </div>
                    <span class="font-semibold flex text-indigo-600 dark:text-indigo-300 font-mono text-2xl items-center">{{"CAREER.TITLE"|translate}} <img src="assets/images/rocket.png" class="ml-2 w-10" alt="fire"></span>
                </div>

 
                <app-timeline [data]="data" [labelForLastPeriod]="'CAREER.ACTUAL'|translate"></app-timeline>
    
         
        </div>
      
    </div>
</section>

