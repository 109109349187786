import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[reveal]',
  standalone: true
})
export class RevealerDirective implements AfterViewInit {

  constructor(private elementRef:ElementRef) { }

  ngAfterViewInit(): void {
      this.elementRef.nativeElement.textContent = "asdkfjsdk"
  }

}
