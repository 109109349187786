import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AboutMeComponent } from './pages/about-me/about-me.component';
import { SvgModule } from './svg/svg.module';
import { StackComponent } from './components/stack/stack.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ContactComponent } from './pages/contact/contact.component';
import { DarkModeModule } from './dark-mode/dark-mode.module';
import { LanguageService } from './services/language.service';
import { SliderModule } from './components/slider/slider.module';
import { TimelineModule } from './components/timeline/timeline.module';
import { MySkillsComponent } from './pages/my-skills/my-skills.component';
import { MyHistoryComponent } from './pages/my-history/my-history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from './components/calendar/calendar.module';
import { RevealerDirective } from './components/revealer.directive';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    ContactComponent,
    MySkillsComponent,
    MyHistoryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CalendarModule,
    ComponentsModule,
    SvgModule,
    SliderModule,
    TimelineModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RevealerDirective,
    DarkModeModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    LanguageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
