	<svg width="75" height="75" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 825.63 825.63" >
		<rect width="825.63" height="825.63" style="fill: #6366f1"/>
		<g>
		  <path d="M684.12,229.18V496.83A135.24,135.24,0,0,1,548.88,632.06a137.07,137.07,0,0,1-15.38-.86A135.29,135.29,0,0,0,413.65,465.56V229.18a135.24,135.24,0,1,1,270.47,0Z" transform="translate(-1.5 -1.91)" style="fill: #f4f4f5"/>
		  <path d="M306.2,504.66A135.49,135.49,0,0,0,270.28,631.8c-2.82.17-5.68.26-8.55.26A135.2,135.2,0,1,1,393.27,465.31,134.8,134.8,0,0,0,306.2,504.66Z" transform="translate(-1.5 -1.91)" style="fill: #18181b"/>
		  <path d="M533.5,631.2a135.27,135.27,0,0,1-263.22.6,135.38,135.38,0,0,0,123-166.49q4.25-.26,8.55-.26,6,0,11.83.51v31.27A135.28,135.28,0,0,0,533.5,631.2Z" transform="translate(-1.5 -1.91)" style="fill: #e0e7ff"/>
		  <path d="M397,496.83a135.26,135.26,0,0,1-126.68,135,135.29,135.29,0,0,1,123-166.49A135.83,135.83,0,0,1,397,496.83Z" transform="translate(-1.5 -1.91)" style="fill: #a5b4fc"/>
		  <path d="M537.05,600.28a135.77,135.77,0,0,1-3.55,30.92A135.28,135.28,0,0,1,413.65,496.83V465.56a135.25,135.25,0,0,1,123.4,134.72Z" transform="translate(-1.5 -1.91)" style="fill: #a5b4fc"/>
		</g>
	  </svg>
