<svg class="w-full width-contact-fix" id="Layer_3" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" width="483.69" height="388.83" viewBox="0 0 483.69 388.83">
    
    <g id="base" >
      <polygon points="483.63 280.62 483.69 280.64 483.63 280.69 436.86 307.69 436.81 307.71 390.03 334.73 389.98 334.75 343.21 361.75 343.15 361.8 296.37 388.8 296.32 388.82 296.32 388.76 140.54 299.76 140.49 299.8 140.49 299.74 93.71 272.73 93.66 272.75 93.66 272.69 46.88 245.69 46.83 245.72 46.83 245.66 0.06 218.66 0 218.69 0 164.53 0.05 164.57 0.06 164.57 46.83 137.56 46.83 137.5 46.88 137.53 93.66 110.53 93.66 110.47 93.71 110.5 140.49 83.5 140.49 83.42 140.54 83.47 187.32 56.45 187.32 56.4 187.37 56.42 234.15 83.44 234.21 83.47 280.98 110.47 281.03 110.5 327.81 137.5 327.86 137.53 483.63 226.53 483.69 226.57 483.63 226.62 483.63 280.62"
       class="fill-current text-gray-900  dark:text-indigo-500"/>
      <polygon id="left" points="296.32 334.69 296.32 388.76 140.54 299.76 140.49 299.74 93.71 272.73 93.66 272.69 46.88 245.69 46.83 245.66 0.06 218.66 0 218.62 0 164.6 0.05 164.57 0.06 164.57 46.83 191.57 46.83 191.65 46.89 191.62 93.66 218.62 93.66 218.68 93.72 218.65 140.49 245.66 140.49 245.72 140.55 245.69 296.32 334.69" 
      class="fill-current text-gray-100 dark:text-indigo-300"/>
      <polygon id="top" points="327.84 137.53 327.79 137.56 327.79 137.5 281.01 110.5 280.96 110.53 280.96 110.47 234.19 83.46 234.13 83.49 234.13 83.43 187.35 56.42 187.3 56.45 140.52 83.46 140.47 83.49 93.69 110.5 93.64 110.52 46.86 137.53 46.81 137.56 0.04 164.57 46.81 191.57 46.87 191.61 93.64 218.61 93.7 218.64 140.47 245.65 140.53 245.68 296.3 334.68 296.36 334.72 343.13 307.71 343.13 307.65 343.18 307.68 389.96 280.68 389.96 280.62 390.01 280.65 436.79 253.64 436.79 253.57 436.85 253.61 483.61 226.61 483.61 226.53 327.84 137.53" 
      class="fill-current text-indigo-500 dark:text-indigo-100"/>
    </g>
    <g id="pyramid">
      <polygon id="shadow" points="198.61 189.31 363.58 221.94 308.4 127 198.61 189.31" class="fill-current text-indigo-900" style="opacity: 0.58"/>
      <g id="base-2" data-name="base">
        <polygon points="87.64 127 161.22 42.13 198.61 63.5 198.61 189.31 87.64 127" class="fill-current text-bluegray-50 dark:text-indigo-50"/>
        <polygon points="308.4 127 198.61 189.31 198.61 63.5 235.4 42.73 308.4 127" class="fill-current text-bluegray-900 dark:text-indigo-400"/>
        <polygon points="235.4 42.73 198.61 63.5 161.22 42.13 198.02 21.36 235.4 42.73" class="fill-current text-bluegray-300 dark:text-indigo-300"/>
      </g>
      <g id="pyramid-2" data-name="pyramid">
        <polygon points="166.56 36.79 198.61 55.19 198.61 0 166.56 36.79" class="fill-current text-indigo-500"/>
        <polygon points="230.66 36.79 198.61 55.19 198.61 0 230.66 36.79" class="fill-current text-indigo-800"/>
      </g>
    </g>
    <g id="cube">
      <polygon id="sombra" points="74.16 164.38 42.41 182.72 74.39 201.03 106.09 182.53 74.16 164.38" class="fill-current text-indigo-900" style="opacity: 0.58"/>
      <g class="infinite-hover  animation-delay-400">
        <polygon id="right" points="105.81 86.34 105.81 123.05 74.06 141.38 74.02 141.35 74.02 104.69 74.06 104.67 105.81 86.34" 
        class="fill-current text-gray-900 dark:text-indigo-700"/>
        <polygon id="left-2" data-name="left" points="74.02 104.64 74.02 141.35 42.27 123.03 42.24 123.01 42.24 86.34 42.27 86.32 74.02 104.64" 
        class="fill-current text-gray-100 dark:text-indigo-500"/>
        <polygon id="top-2" data-name="top" points="105.81 86.3 105.81 86.34 74.06 104.67 74.02 104.64 42.27 86.32 74.02 67.99 74.06 67.97 105.81 86.3" 
        class="fill-current text-indigo-500 dark:text-indigo-600"/>
      
      </g>
   </g>
    <g id="cube-2" data-name="cube">
      <polygon id="sombra-2" data-name="sombra" points="417.79 199.64 369.64 227.45 418.14 255.23 466.2 227.16 417.79 199.64" class="fill-current text-indigo-900" style="opacity: 0.58"/>
      <g class="infinite-hover">
        <polygon id="right-2" data-name="right" points="465.79 81.3 465.79 136.96 417.64 164.76 417.58 164.72 417.58 109.12 417.64 109.09 465.79 81.3" 
        class="fill-current text-gray-900 dark:text-indigo-700"/>
        <polygon id="left-3" data-name="left" points="417.58 109.05 417.58 164.72 369.43 136.93 369.38 136.9 369.38 81.29 369.43 81.25 417.58 109.05" 
        class="fill-current text-gray-100 dark:text-indigo-500"/>
        <polygon id="top-3" data-name="top" points="465.79 81.22 465.79 81.3 417.64 109.09 417.58 109.05 369.43 81.25 417.58 53.46 417.64 53.43 465.79 81.22" 
        class="fill-current text-indigo-500 dark:text-indigo-600"/>
      
      </g>
   </g>
    <g id="cube-3" data-name="cube">
      <polygon id="sombra-3" data-name="sombra" points="241.37 251.23 219.63 263.78 241.53 276.32 263.23 263.65 241.37 251.23" class="fill-current text-indigo-900" style="opacity: 0.58"/>
      <g class="infinite-hover animation-delay-200">
          
      <polygon id="right-3" data-name="right" points="263.04 197.79 263.04 222.92 241.31 235.48 241.28 235.46 241.28 210.36 241.31 210.34 263.04 197.79" 
      class="fill-current text-gray-900 dark:text-indigo-700"/>
      <polygon id="left-4" data-name="left" points="241.28 210.32 241.28 235.46 219.54 222.91 219.52 222.9 219.52 197.79 219.54 197.78 241.28 210.32" 
      class="fill-current text-gray-100 dark:text-indigo-500"/>
      <polygon id="top-4" data-name="top" points="263.04 197.76 263.04 197.79 241.31 210.34 241.28 210.32 219.54 197.78 241.28 185.22 241.31 185.21 263.04 197.76"
       class="fill-current text-indigo-500 dark:text-indigo-600"/>
    
      </g>
      </g>
  </svg>
  