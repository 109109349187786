import { Component, OnInit } from '@angular/core';
import SimpleParallax from 'simple-parallax-js';

@Component({
  selector: 'app-isometric-cubes',
  templateUrl: './isometric-cubes.component.html',
  styleUrls: ['./isometric-cubes.component.scss']
})
export class IsometricCubesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // this.createParallaxObject('base','up',1.5);
  }

  
  createParallaxObject(className:string,orientation:'down'|'up'|'left'|'right',scale:number = 1){
    let image = document.getElementsByClassName(className);

    return new SimpleParallax(image,{
      orientation:orientation,
      overflow:true,
      scale:scale
    });
  }

}
