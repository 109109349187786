import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { KeepReadingComponent } from '../keep-reading/keep-reading.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    TimelineComponent
  ],
  imports: [
    CommonModule,
    KeepReadingComponent,
    TranslateModule
  ],
  exports:[
    TimelineComponent
  ]
})
export class TimelineModule { }
