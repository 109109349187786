
<div class="relative lg:py-48 overflow-hidden min-h-screen">
  <div class="absolute lg:top-0 -top-72 -left-52 z-10 w-[800px] md:w-full h-full ">
    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="z-10 absolute lg:left-96 blobs-2 w-full h-full">
      <path  class="fill-current text-indigo-400 dark:text-indigo-800" d="M46.1,-39.1C62,-30.3,78.5,-15.1,82.5,4C86.5,23.1,77.8,46.2,62,60.3C46.2,74.4,23.1,79.6,1.9,77.7C-19.3,75.8,-38.6,66.9,-49.6,52.7C-60.6,38.6,-63.3,19.3,-64.2,-0.9C-65,-21,-64.1,-42.1,-53.1,-50.9C-42.1,-59.7,-21,-56.2,-2.9,-53.3C15.1,-50.4,30.3,-47.9,46.1,-39.1Z" transform="translate(100 100)" />
    </svg>
    <!-- <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="absolute blobs-3 w-full h-full">
      <path class="fill-current text-indigo-200" d="M22.1,7.1C12.8,28.8,-21.4,30.4,-29.2,9.6C-37.1,-11.3,-18.5,-54.7,-1.4,-55.6C15.7,-56.4,31.4,-14.6,22.1,7.1Z" transform="translate(100 100)" />
    </svg> -->
    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="z-40 absolute lg:left-96 blobs-4 w-full h-full">
      <path class="fill-current text-indigo-500 dark:text-indigo-700" d="M39.5,23.8C29,41,-17,38.7,-29.5,20.4C-42.1,2,-21,-32.5,2,-31.3C25,-30.2,50.1,6.6,39.5,23.8Z" transform="translate(100 100)" />
    </svg>
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="relative lg:left-80 blobs w-full h-full">
                    <path class="fill-current  text-indigo-300 dark:text-indigo-900" d="M59.4,-55.1C72.1,-46.6,74.3,-23.3,69.4,-4.9C64.5,13.5,52.5,27,39.8,39C27,51,13.5,61.4,-3.8,65.1C-21.1,68.9,-42.2,66.1,-56.8,54.1C-71.5,42.2,-79.7,21.1,-79.8,-0.1C-79.9,-21.2,-71.8,-42.5,-57.1,-50.9C-42.5,-59.3,-21.2,-55,1,-56C23.3,-57,46.6,-63.5,59.4,-55.1Z" transform="translate(100 100)" />
                  </svg>

                
                 
    
            
            </div>
<div class="relative z-30  h-auto w-screen flex flex-col-reverse lg:flex-row ">
  

    <div class=" flex-1 justify-center w-full flex relative z-20" >
        <div class="-mt-12 md:mt-auto w-full sm:pr-24 px-11 lg:px-32 lg:pl-48 flex items-center">
          <div class=" flex flex-1  w-full flex-col justify-center relative ">
            <!-- <pre-title [text]="'Hey!'"> </pre-title> -->
        <h1  class=" text-5xl lg:text-6xl xl:text-7xl font-semibold font-sans dark:text-bluegray-50">
            <span data-aos-once="true" data-aos="animation-scale-y" data-aos-delay="200" class="flex">{{"HERO.HELLO_1"|translate}} <img class="md:w-auto w-10 object-contain ml-2" src="assets/images/hand.png" alt=""> </span>
            <p data-aos-once="true" data-aos="animation-scale-y" data-aos-delay="1000" class="tracking-tighter">{{"HERO.HELLO_2"|translate}}</p>
            <!-- <p data-aos="animation-scale-y" data-aos-delay="1200" class="leading-tight xl:whitespace-nowrap absolute ">{{"HERO.HELLO_3"|translate}}</p> -->
            <p data-aos-once="true" data-aos="animation-scale-y" data-aos-delay="1700" class="leading-tight text-4xl mt-2 md:mt-5 tracking-tighter md:tracking-tight ">
              {{"HERO.HELLO_3"|translate}}
            </p>
            <p data-aos-once="true"  data-aos="animation-scale-y" data-aos-delay="2300" class="text-xl md:text-2xl font-mono font-light mt-2 md:mt-5 max-w-lg">
              {{"CUENTOCON"|translate}}
            </p>
          </h1>
          <div class="w-full flex gap-5 mt-8 md:mt-14" data-aos-once="true" data-aos="animation-scale-y" data-aos-delay="3000" >
            <a href="assets/resumee2024nophone.pdf" target="_blank"  class="w-min p-4 disabled:opacity-60 disabled:cursor-not-allowed disabled:active:translate-y-0 transition-all font-mono bg-indigo-600 focus:ring-8 focus:ring-indigo-300 active:translate-y-2 rounded-2xl text-gray-50 text-lg">
              <div class="flex justify-between items-center whitespace-nowrap">
                {{"RESUMEE"|translate}}
                <em class="ml-1 pi pi-download  text-indigo-100"></em>
              </div>
          </a>
            <a  href="https://github.com/jorgepazp" target="_blank" rel="noopener noreferrer"  class="hidden md:block w-min p-4 disabled:opacity-60 disabled:cursor-not-allowed disabled:active:translate-y-0 transition-all font-mono  border-gray-900 dark:border-gray-100 border-4 focus:ring-8 focus:ring-indigo-300 dark:focus:ring-indigo-500 active:translate-y-2 rounded-2xl text-gray-900 dark:text-gray-50 text-lg">
              <a class="flex justify-between items-center">
                  Github <em class="pi pi-github text-lg ml-2"></em>
              </a>
          </a>
            <a  href="https://www.linkedin.com/in/jorge-paz-pavez-34878517a/" target="_blank" rel="noopener noreferrer"  class="hidden md:block w-min p-4 disabled:opacity-60 disabled:cursor-not-allowed disabled:active:translate-y-0 transition-all font-mono  border-gray-900 dark:border-gray-100 border-4 focus:ring-8 focus:ring-indigo-300 dark:focus:ring-indigo-500 active:translate-y-2 rounded-2xl text-gray-900 dark:text-gray-50 text-lg">
              <div class="flex justify-between items-center w-max">
                  LinkedIn <img alt="linkedin" class="w-4 ml-2 brightness-0 dark:brightness-100 " src="assets/images/linkedin.svg">
              </div>
          </a>
        

      </div>
      <div data-aos="animation-scale-y" data-aos-once="true" data-aos-delay="3000" class="flex mt-11 md:mt-20 w-min " (click)="ns.scrollToId('project')">
        <button class="transition-all rounded-2xl flex items-center p-5 focus:ring-8 focus:ring-indigo-200 bg-gray-50  hover:scale-95">
            <em class="pi pi-angle-double-down animate-bounce text-indigo-900 dark:text-indigo-400"></em>
        </button>
        <div class="cursor-pointer flex ml-5 items-center font-medium text-indigo-900 dark:text-indigo-200 w-56">
            {{"LEARN_MORE"|translate}}
        </div>
        </div>
      </div>
          <!-- <div class="flex items-center mb-5 ">
            <div class="h-0.5 w-10 bg-indigo-600 dark:bg-indigo-300 mr-5 ">
            </div>
        
            <span class="font-semibold flex text-indigo-600 dark:text-indigo-300 font-mono text-2xl items-center">{{"ABOUT.TITLE"|translate}} <img src="assets/images/man.png" class="ml-2 w-10" alt="fire"></span>
        </div> -->
            <!-- <h1 data-aos="animation-scale-y" data-aos-delay="200" class=" text-5xl lg:text-9xl  font-semibold font-sans dark:text-bluegray-50">
              <p class="mix-blend-difference after:text-red-500">
                {{'ABOUT.MOTTO'|translate}}
                 </p>
             </h1>
             <div class="w-full py-9 flex flex-col xl:flex-row text-lg font-medium font-mono">
               <div class="flex-1 xl:pr-5">
                {{'ABOUT.DESCRIPTION_1'|translate}}
               </div>
               <div class="flex-1 xl:pl-5 pt-5 xl:pt-0">
                {{'ABOUT.DESCRIPTION_2'|translate}}
               </div>
             </div> -->
            <!-- <div class="font-sans text-2xl bg-white border border-opacity-50 border-gray-100 drop-shadow-lg rounded-md p-5 bg-opacity-60 bg-clip-padding backdrop-blur-sm"> -->

            <!-- </div> -->
           
        </div>
    </div>

    <div class="w-5/12 p-12 justify-center items-center flex z-[5]">
       
        <img class="-right-24 sm:-right-36 lg:right-52 image max-w-xl relative z-30  w-72 md:w-96" src="assets/images/perfil.png" alt="">
        
    </div>
</div>
<div class="w-full h-96 z-20 bg-gradient-to-t absolute bottom-0 dark:from-bluegray-900 from-indigo-100
"></div>
<div class="w-full h-96 z-20 bg-gradient-to-b absolute top-0 dark:from-bluegray-900 from-indigo-100
"></div>
</div>