    <div class="w-full">

        <ul class="timeline">
            <li  data-aos="animation-scale-y" [attr.data-aos-delay]="500*i" data-aos-anchor=".timeline"  *ngFor="let d of data;let i = index;let odd = odd;let last = last;let first = first" [ngClass]="{'timeline-inverted':odd,'md:-mt-52':!first}">
                <div class="timeline-image relative z-20">
                    <div *ngIf="last" class="bg-indigo-400 w-[50px] h-[50px] duration-1000 rounded-full animate-ping absolute top-0 left-0 right-0 bottom-0  "></div>
                </div>
                <div class="timeline-panel">
                    <div class="mb-4">
                        <h4 class=" mb-6">
                            <span class=" p-3  rounded-xl tracking-[.5rem] font-extrabold text-xl font-serif">{{d.period}}{{last?labelForLastPeriod:''}}</span>
                        </h4>
                        <h4 class="font-extrabold text-3xl tracking-widest dark:text-indigo-100 text-indigo-600 ">{{d.title}}</h4>
                        <h6 class="font-semibold text-xl dark:text-indigo-300 text-bluegray-800 ">{{d.subtitle}}</h6>
                    </div>
                    <div class="timeline-body">
                        <p class="text-base  font-mono">
                            {{d.description}}
                        
                        </p></div>
                </div>
            </li>
           
        </ul>
    </div>