<section class="mt-5 mr-5 h-auto py-12 ">
    <div class="relative flex h-auto w-screen  lg:flex-row flex-col ">
        <div class=" flex flex-1 lg:px-48 px-12 flex-col justify-center relative  max-w-[100vw] ">
                <div class="flex items-center mb-5 ">
                    <div class="h-0.5 w-10 bg-indigo-600 dark:bg-indigo-300 mr-5 ">
                    </div>
                    <span class="font-semibold flex text-indigo-600 dark:text-indigo-300 font-mono text-2xl items-center">{{"SKILLS.TITLE"|translate}} <img src="assets/images/fire.png" class="ml-2 w-10" alt="fire"></span>
                </div>
                <p class="font-mono text-lg pb-3">
                    {{'SKILLS.DESCRIPTION'|translate}}
                </p>

                <app-slider class="pt-24" [value]="skillset" [bgColor]="darkMode.currentBg" [darkMode]="darkMode.darkModeEnabled"></app-slider>

    
         
        </div>
      
    </div>
</section>


<div class="relative flex h-[800px] mt-40 lg:py-20 w-screen  lg:flex-row flex-col  ">
    <div class=" flex flex-1  flex-col justify-center  relative pl-12 lg:pl-48 ">
        <!-- <div class="bg-white border border-opacity-50 border-gray-100 drop-shadow-lg rounded-md p-5 bg-opacity-60 bg-clip-padding backdrop-blur-sm"> -->
            <h1 class=" text-3xl font-light font-sans dark:text-bluegray-50 pr-8 flex gap-3 flex-col items-center text-center">
                <span class="block text-2xl" data-aos="animation-scale-y" data-aos-delay="10" >
                  {{"EINSTEIN_1"|translate}}

                </span>
                <span class="block text-4xl font-light" data-aos="animation-scale-y" data-aos-delay="1000">
                  {{"EINSTEIN_2"|translate}}

                </span>
                <span class="block text-4xl font-extrabold py-1 from-violet-500 bg-gradient-to-tr via-indigo-400  to-fuchsia-500 text-[transparent] bg-clip animate-gradient bg-clip-text " data-aos="animation-scale-y" data-aos-delay="2000">
                  {{"EINSTEIN_3"|translate}}
                    
                </span>
            </h1>
            <div class="w-full flex justify-end pr-20 pt-8" data-aos="animation-scale-y" data-aos-delay="3000">

                <pre-title [text]="'Albert Einstein'"> </pre-title>
            </div>

 
    </div>
    <div data-aos="fade-in" class="z-30 relative  sm:block flex-1 overflow-hidden">
        <div class="mt-5 mr-5 absolute w-full">
            <app-isometric-cubes ></app-isometric-cubes>
        </div>
        <div class="w-full h-80 bg-gradient-to-t absolute -mb-1  bottom-0 dark:from-bluegray-900 from-indigo-100"></div>
    </div>
</div>